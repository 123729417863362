body.transition {
    overflow-x: hidden;
}

.transition-wrapper {
    position: relative;
}

.transition-wrapper .content-wrapper-exit > .main-content-wrapper {
    z-index: 1;
}

.transition-wrapper .content-wrapper-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.transition-wrapper .content-wrapper-enter > .main-content-wrapper {
    z-index: 10;
}

.transition-wrapper.left .content-wrapper-enter > .main-content-wrapper {
    transform: translate3d(100%, 0, 0);
}

.transition-wrapper.right .content-wrapper-enter > .main-content-wrapper {
    transform: translate3d(-100%, 0, 0);
}

.transition-wrapper .content-wrapper-enter.content-wrapper-enter-active > .main-content-wrapper {
    transform: translate3d(0, 0, 0);
    transition: all 800ms ease-in-out;
}

.transition-wrapper.left .content-wrapper-exit > .main-content-wrapper {
    transform: translate3d(-100%, 0, 0);
}

.transition-wrapper.right .content-wrapper-exit > .main-content-wrapper {
    transform: translate3d(100%, 0, 0);
}

.transition-wrapper .content-wrapper-exit > .main-content-wrapper {
    transition: all 800ms ease-in-out;
}