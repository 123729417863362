.login-modal .wrapper {
  max-width: 500px !important;
  margin: 0 auto;
}

.login-modal .wrapper .login-form label input {
  width: 100%;
  height: 55px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 1px solid #183057;
  padding: 5px 15px;
  font-size: 22px;
}

.login-modal .wrapper .login-form label input.error {
  border-color: #e62600;
}

.login-modal .wrapper .login-form label input:focus {
  outline: none;
}

.login-modal .wrapper .login-form label span.label {
  font-family: 'Helvetica Bold';
  color: #183057;
  padding-bottom: 10px;
}

.login-modal .wrapper .login-form label span.error {
  color: #e62600;
  font-size: 16px;
}

.login-modal .wrapper .content-footer {
  margin-top: 35px !important;
}

.login-modal .wrapper .login-form .btn-theme:active, .login-modal .wrapper .login-form .btn-theme:focus {
  background-color: #183057;
}

.login-modal .wrapper .login-form .btn-theme:hover {
  background-color: rgba(24,48,87,0.8);
}
